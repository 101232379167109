.cards {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.card {
  /* margin: 40px; */
  position: relative;
  width: 305px;
  height: 305px;
}

.card img {
  width: 100%;
  height: 98%;
  object-fit: cover;
  display: block;
  position: relative;
}

.card-desc {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 50%;
  display: block;
  font-size: 14px;
  color: #dbdbdb;
  position: absolute;
  height: 0;
  top: 0;
  opacity: 0;
  /* padding: 18px 8%; */
  justify-content: start;
  background-color: transparent;
  overflow-y: scroll;
  transition: 0.3s ease;
}

.card:hover .card-desc {
  opacity: 0.8;
  background-color: #121a34cc;
  height: 50%;
}
