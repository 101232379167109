@keyframes mymove {
  50% {
    box-shadow: 0px 0px 40px 10px rgba(119, 103, 168, 0.84);
  }
}

.starCl {
  height: 5px;
  width: 5px;
  background-color: #4a16ba;
  border-radius: 50%;
  animation-name: mymove;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(1, -0.39, 0.59, 1.15);
}
.starCl:nth-child(2n + 1) {
  animation-delay: -1;
}
.starCl:nth-child(2n) {
  animation-delay: 2;
}
