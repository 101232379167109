.buttonstyle {
  all: unset;
  display: flex;
  background-color: transparent;
  position: relative;
  width: 135px;
  height: 48px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  padding-left: 22px;
  padding-right: 22px;
}

.buttonstyle:hover {
  background: linear-gradient(94.67deg, #4a16ba 0%, #ba6516 100%);
  border-radius: 10px;
}
.buttonstyle::before {
  content: "";
  position: absolute;
  cursor: pointer;
  inset: 0;
  border-radius: 10px;
  padding: 2px; /* control the border thickness */
  background: linear-gradient(94.67deg, #4a16ba 0%, #ba6516 100%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
}
